import { CONTACT, PATHS, SERVICES, SOCIAL } from "../../config/urls";
import {
  ContactDiv,
  CustomLink,
  CustomLinkHome,
  Disclaimer,
  FooterContainer,
  FooterContent,
  InternalWrapper,
  ItemsDiv,
  ListItem,
  Logo,
  LogosDiv,
  SectionDiv,
  SocialMedia,
  SocialMediaIcon,
  Text,
  TextPhone,
  Title
} from "./styles";

import AOS from "aos";
import Life from "../../images/LIFE.svg";
import { Link } from "gatsby";
import React from "react";
import facebookRed from "./assets/facebookRed.svg";
import instagramRed from "./assets/instagramRed.svg";
import linkedInRed from "./assets/linkedInRed.svg";

const Footer = () => {
  React.useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <FooterContainer>
      <InternalWrapper>
        <FooterContent>
          <LogosDiv>
            <Link to={PATHS.INDEX}>
              <Logo src={Life} alt="Life logo" />
            </Link>
            <SocialMedia>
              <a>
                <SocialMediaIcon src={facebookRed} alt="facebook" />
              </a>
              <a>
                <SocialMediaIcon src={instagramRed} alt="instagram" />
              </a>
              <a>
                <SocialMediaIcon src={linkedInRed} alt="linkedin" />
              </a>
            </SocialMedia>
          </LogosDiv>
          <ItemsDiv>
            <SectionDiv>
              <Title>Our Services</Title>
              <ul>
                <ListItem>
                  <CustomLinkHome to={SERVICES.HOME_CARE}>
                    Home Care
                  </CustomLinkHome>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.HOME_THERAPY}>
                    Home Therapy
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.RIDE}>Ride</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.DME}>
                    Durable Medical Equipment
                  </CustomLink>
                </ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Coverage Area</Title>
              <ul>
                <ListItem>Essex</ListItem>
                <ListItem>Morris</ListItem>
                <ListItem>Union</ListItem>
                <ListItem>Somerset</ListItem>
                <ListItem>Passaic</ListItem>
                <ListItem>Bergen</ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Contact</Title>
              <ContactDiv>
                <a
                  href="https://goo.gl/maps/zPEAcwvDqJFskST97"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Text>
                    70 South Orange Ave, Suite 245, Livingston, NJ 07039
                  </Text>
                </a>
              </ContactDiv>
              <ContactDiv mobile>
                <a href={`tel:${CONTACT.PHONE}`}>
                  <TextPhone>{CONTACT.PHONE_TO_DISPLAY}</TextPhone>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a href={`mailto:${CONTACT.MAIL}`}>
                  <Text>hello@hospicecare.life</Text>
                </a>
              </ContactDiv>
            </SectionDiv>
          </ItemsDiv>
        </FooterContent>
        <FooterContent>
          <Disclaimer>
          Life Ride, Life Home Care, Life DME, Life Home Therapy, Life Hospice, Life Health Group logos are all Registered in U.S. Patent and Trademark Oﬃce
          </Disclaimer>
        </FooterContent>
      </InternalWrapper>
    </FooterContainer>
  );
};

export default Footer;
